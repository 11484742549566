import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'

// Elements
import ParseContent from 'components/shared/ParseContent'
import NavLink from 'components/shared/NavLink'

// Images
import Arrow from 'img/arrow-circle-light.inline.svg'

// Interface
import { MarketsProps } from 'components/flex/Markets/MarketsShell'
import Plaatjie from '@ubo/plaatjie'

const Section = styled.div``

const Content = styled(ParseContent)`
  @media (min-width: 992px) {
    & h1,
    & h2,
    & h3,
    & h4,
    & h5,
    & h6 {
      &:first-child {
        font-size: 50px;
        line-height: 40px;
        margin-bottom: 0;
      }
    }
  }
`

const ItemCSS = css`
  background-color: ${({ theme }) => theme.color.primary};
  font-family: ${({ theme }) => theme.font.family.secondary};
  color: ${({ theme }) => theme.color.light};

  @media (min-width: 992px) {
    font-size: ${({ theme }) => theme.font.size.bigger};
    line-height: ${({ theme }) => theme.font.size.large};
  }

  @media (max-width: 575.98px) {
    font-size: ${({ theme }) => theme.font.size.medium};
    line-height: ${({ theme }) => theme.font.size.big};
  }
`

const Market = styled(motion.div)`
  ${ItemCSS}

  @media (min-width: 1200px) {
    aspect-ratio: 1/1;
  }

  @media (max-width: 575.98px) {
    font-size: ${({ theme }) => theme.font.size.tiny};
    text-align: center;
  }
`

const LastItem = styled(motion.div)`
  ${ItemCSS}

  /* @media (max-width: 767.98px) {
    &:before {
      position: absolute;
      content: '';
      right: 100%;
      top: 0;
      height: 100%;
      width: 2000px;
      background-color: ${({ theme }) => theme.color.primary};
    }
  } */

  @media (max-width: 575.98px) {
    font-size: ${({ theme }) => theme.font.size.tiny};
    text-align: center;
  }
`

const SVGWrapper = styled.div`
  left: 0;
  top: calc(50%);

  @media (max-width: 575.98px) {
    width: 75px;
    height: 75px;

    & img {
      width: 100%;
      height: 100%;
    }
  }
`

const Markets: React.FC<MarketsProps> = ({ fields }) => {
  const [hovered, setHovered] = useState(-1)
  const lastItem = fields.markets?.length ? fields.markets?.length - 1 : 0

  return (
    <Section className="mb-5 pb-lg-5">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-11">
            <div className="row">
              <div className="col-12 col-lg-6 order-lg-1 order-2">
                <Content
                  content={fields.description}
                  className="pe-lg-5 mt-lg-0 mt-5"
                />
              </div>
              <div className="col-12 col-lg-6 order-lg-2 order-1">
                <div className="row">
                  {fields.markets?.map((market, index) => (
                    <React.Fragment key={index}>
                      {index !== lastItem && (
                        <div className="col-md-6 mb-4" key={index}>
                          <NavLink
                            to={market?.link?.url || '#'}
                            className="d-block h-100 w-100"
                          >
                            <Market
                              className="position-relative d-flex flex-column justify-content-between align-items-center p-2 p-sm-4 h-100"
                              onHoverStart={() => setHovered(index)}
                              onHoverEnd={() => setHovered(-1)}
                            >
                              <SVGWrapper className="mt-4">
                                <Plaatjie image={market?.icon} alt="" />
                              </SVGWrapper>
                              <div className="d-flex align-items-center justify-content-center justify-content-sm-between w-100">
                                <span className="px-sm-3">
                                  {market?.link?.title}
                                </span>
                                <motion.div
                                  animate={
                                    hovered === index
                                      ? { rotate: 90, y: 10 }
                                      : { rotate: 0, y: 0 }
                                  }
                                  className="d-none d-sm-block"
                                >
                                  <Arrow />
                                </motion.div>
                              </div>
                            </Market>
                          </NavLink>
                        </div>
                      )}
                      {index === lastItem && (
                        <div className="col-12" key={index}>
                          <NavLink to={market?.link?.url || '#'}>
                            <LastItem
                              className="position-relative w-100 align-items-center px-4 px-lg-5 py-2"
                              onHoverStart={() => setHovered(index)}
                              onHoverEnd={() => setHovered(-1)}
                            >
                              <div className="position-relative px-lg-0 px-2">
                                <div className="d-flex flex-column flex-sm-row align-items-center justify-content-between w-100 me-5">
                                  <SVGWrapper
                                    className="position-relative"
                                    style={{ top: 9 }}
                                  >
                                    <Plaatjie image={market?.icon} alt="" />
                                  </SVGWrapper>
                                  <div className="d-flex align-items-center justify-content-center justify-content-sm-start mt-md-0 mt-3">
                                    <span>{market?.link?.title}</span>
                                    <motion.div
                                      animate={
                                        hovered === index
                                          ? { x: 25 }
                                          : { x: 10 }
                                      }
                                      className="d-none d-sm-block"
                                    >
                                      <Arrow />
                                    </motion.div>
                                  </div>
                                </div>
                              </div>
                            </LastItem>
                          </NavLink>
                        </div>
                      )}
                    </React.Fragment>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Markets
